

 body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: $font-primary;
    background-color: $clr-gray;


    @include bp-576{
        overflow-x: hidden;
    }

    &.open{
        overflow: hidden;
        position: fixed;
    }
 }

 img{
    display: block;
 }

section{
    background-color: $clr-gray !important;
}

::-moz-selection
 {
    background: $clr-rojo-bt;
 }
 
::selection {
    background: $clr-rojo-bt;
 }

 .container__bt{
    @include container-padding;
 }

 .cover{
    height: 90vh;
    width: 100%;
    display: block;
    object-fit: cover;
}


@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { 
    header.fixi {
        top: 165px !important;
    }
}

 header{
    top: 17px;
    height: 540px;
    // height: 387px;
    // background-color: $clr-gray;
    display: flex;
    display:-webkit-box;
    display:-webkit-flex;
    display:-webkit-flexbox;
    flex-wrap: wrap;
    justify-content: space-between; /* horizontal spacing */
    align-content: space-between;   /* vertical spacing */
    transition: height .3s;
    // border-bottom: 3px solid #000;
    position: fixed;
    width: 100%;
    z-index: 999;

    @include bp-576{
        top: -500px;
        // height: 100vh;
        display: block;
        padding: 0;
        height: 0;
    }

    &.fixi{
        position: absolute; 
        top: 126px;
    }

    &.menu__on{
        position: fixed !important;
        height: 0px !important;
        top: 48px;

        .m__top{
            display: none;
        }
    }

    &.activo{
        @include bp-576{
            background: $clr-gray;
            height: 100vh;
            top: 0px;
        }

        .m__top{
            @include bp-576{
                opacity: 1;
                top: 55px;
                display: block;
            }
        }

        .m__bottom{
            .menu__title{
                .menu__button{
                    @include bp-576{
                        display: none;
                    }  
                }
            }

            #menu__button__close{
                display: block;
            }
        }
    }    

    .container-mtop{
        display:flex;
        justify-content:space-between;
        flex-wrap:wrap;
        border-bottom: 3px solid #000;

        .bt-logo{
            font-size: size(38);
            line-height: size(38);
            display: block;
            margin-top: -50px;

            @include bp-576{
                display: none;
            }
        }
    }

    .m__top, .m__bottom{
        flex-basis: 100%;    /* to force .bottom to wrap */
        display: flex;
        display:-webkit-box;
        display:-webkit-flex;
        display:-webkit-flexbox;
        justify-content: space-between;
        padding: 0 1%;

        .item{
            display:flex;
            flex: 0 1 50%;
            flex-direction:column;
        }

        .item:nth-child(3){
            align-self:flex-end;
            -webkit-align-self: flex-end;
            -ms-flex-item-align: end;
          }
          
          .item:last-child{
            align-self:flex-end;
            -webkit-align-self: flex-end;
            -ms-flex-item-align: end;
          }
          
          .item:first-child span {
              background-color: Crimson;
          }
          
          .item:nth-child(2) span{
              background-color:RoyalBlue;
          }
          
          .item:nth-child(3) span{
              background-color: DarkCyan;
          }
          
          .item:last-child span {
              background-color: Chocolate;
          }
          
          .item:nth-child(2) span,
          .item:last-child span{
              align-self:flex-end;
              -webkit-align-self: flex-end;
              -ms-flex-item-align: end;
          }
    }

    .m__top{
        transition: top .3s;
        background: $clr-gray;

        .m__rrss{
            width: 165px;
            justify-content: start;
            flex-direction: row;

            @include bp-576{
                position: absolute;
                bottom: 4%;
                margin-bottom: 0px !important;
                display: none;
            }

            @include bp-375{
                display: none;
            }

            a{
                font-size: size(15); 
                line-height: size(15); 
                margin-right: 20px;
            }
            @include link__a;

            @include bp-576{
                margin-bottom: 30px;
                width: 100%;
            } 
        }

        @include bp-576{
            flex-direction: column-reverse;
            position: absolute;
            bottom: 0;
            width: 100%;
            background: $clr-gray;
            opacity: 0;
            z-index: 9999999;
            top: -500px;
        }

        .menu__button{
            opacity: 0;
            @include bp-576{
                opacity: 1;
            }
        }
        
    }

    .m__bottom{
            // border-bottom: 3px solid #000;
            // height: 32px;
            // // transition: all .3s;
            // top: -10px;

            @include bp-576{
                height: 50px;
                width: 100%;
                position: fixed;
                margin: 0px;
                background: $clr-gray;
                margin-top: 6px;
                z-index: 999999999;
                border-bottom: 3px solid #000;
            }

            .smooth-scroll{
                display: none;
            }

            .bt-barra{
                display: none;

                @include bp-576{
                    display: block;
                }
            }
    
            &.menu__on{
                position: fixed;
                top: 0 !important;
                width: 100%;
                text-align: right;
                margin-top: 0 !important;
                height: 50px;
                background: $clr-gray;
                border-bottom: 3px solid #000;

                @include bp-576{
                    font-size: size(25);
                    margin: 0;
                    height: 50px;
                    top: 0;
                }

                .smooth-scroll{
                    display: block;
                }

                a{
                    margin-top: 0px;

                    @include bp-576{
                        margin-top: 6px;
                    }
                }
    
                .menu__title{
                    opacity: 1 !important;
                    font-size: size(32);
                    text-transform: uppercase;
                    top: 10px;
        
                    @include bp-576{
                        font-size: size(25);
                        top: 2px;
                    }

                    .menu__button{
                        @include bp-576{
                            top: -120px !important;
                        }  
                    }
                }

                .bt-barra{
                    display: block;
                    margin-top: -4px;

                    @include bp-576{
                        top: 10px;
                    }
                }
            }
            
            @include bp-576{
                margin-top: 1.5vh;
                top: -5px;
            }
    
            .menu__title{
                margin-top: -14px;
                @include bp-576{
                    margin-top: 3px;
                }

                .smooth-scroll{
                    opacity: 1;

                    @include bp-576{
                        opacity: 0;
                    }
                }

                .menu__button{
                    opacity: 0;

                    @include bp-576{
                        opacity: 1;
                        top: 2px;
                    }
                }
            }
    

        

        a{
            margin-top: -41px;
            // margin-top: -40px;
            font-size: size(32);
            text-transform: uppercase;
            margin-bottom: 77px !important;

            @include bp-576{
                margin-top: 6px;
                font-size: size(20);
            }
        }

            @include bp-576{
                margin-top: 6px;
                font-size: size(18);
            }
        }

        #menu__button__close{
            display: none;

            @include bp-576{
                display: none;
                width: 25px;
                height: 25px;
                position: absolute;  
                right: 5px;
                top: 5px;
                z-index: 999999;
                

                &::after{
                    content: '';
                    height: 25px;
                    border-left: 2px solid #000;
                    position: absolute;
                    transform: rotate(45deg);
                    left: 12px;
                }

                &::before{
                    content: '';
                    height: 25px;
                    border-left: 2px solid #000;
                    position: absolute;
                    transform: rotate(-45deg);
                    left: 12px;
                }
            }
        }

        img{
            display: block;
        }
    }

 //end header

 .w-wrapper{
    width: 280px;
    // transition: all .5s;
    align-self: end;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;

    @include bp-576{
        width: 100%;
        top: 2vh;
    }

    &.m__colapse{
        // opacity: 0;
        // height: 0;
    }


 .w__svg{
    width: 280px;
    height: 50px;
    // transition: 0.3s;


    polyline{
        vector-effect: non-scaling-stroke;
    }

    @include bp-576{
        width: 100%;
    }

    &#caja{
        @include bp-375{
            height: 30px !important;
        }
    }

    polyline{
        stroke: #000;
    }

    .p__top{ 
        stroke-width: 3;
    }

    .p__middle{
        stroke-width: 1;
    }

    .p__bottom{
        stroke-width: 3;
    }
 }

 svg.w__svg{
    &.go__down{
        height: 0px !important;
        transition: all .3s;

    }
}

.menu__c{
    text-transform: uppercase;
    
    .about-line{
        background: linear-gradient(to bottom left, $clr-gray calc(50% - 3px), black , $clr-gray calc(50% + 2px) )
    }

    .-flex{
        justify-content: space-between;
    }

    svg{
        overflow: inherit;
    }

    a{
        font-size: size(32);
        line-height: size(37);
        transition: color .3s;

        &:hover{
            color: $clr-rojo-bt;
        }
    }

    div{
        padding: 10px 0;

        @include bp-375{
            padding: 7px 0;
        }
    }

    .m__about{
        border-top: 3px solid #000;
        border-bottom: 3px solid #000;
        
        a{  
            border-right: 3px solid #000;
            padding-right: 10px;

            @include bp-576{
                width: 50%;
            }
        }

        .p__about{
            height: 37px;
            width: 100%;

            @include bp-576{
                width: 50%;
                display: none;
            }
        }
        .p__about__mov{
            display: none;
            height: 37px;
            @include bp-576{
                display: block;
                width: 50%;
            }
        }
    }

    .m__producers{
        border-bottom: 3px solid #000;
        align-items: center;
        display: flex;
        display:-webkit-box;
        display:-webkit-flex;
        display:-webkit-flexbox;
        svg{
            // width: 100px;
            width: 23%;
        }
    }

    .m__bc{
        text-align: center;
        border-bottom: 3px solid #000;
        height: 94px;

        svg{
            width: 51.5px;
        }
    }

    .m__spaces{
        border-bottom: 3px solid #000;
        height: 60px;

        @include bp-375{
            height: 50px;
        }

        .line__space{
            width: 52%;
            display: block;
            @include bp-576{
                display: none;
            }
        }

        .line__space_mov{
            display: none;
            @include bp-576{
                display: block;
                width: 58%;
                overflow: hidden;
            }    
        }
    }

    .m__catalog{
        border-bottom: 3px solid #000;
        height: 60px;
        
        @include bp-375{
            height: 50px;
        }
        .line{
            display: block;
            @include bp-576{
                display: none;
            }
        }

        .line__mov{
            display: none;
            @include bp-576{
                display: block;
            }
        }
    }

    .m__experiences{
        border-bottom: 3px solid #000;
        text-align: center;

        a{
            margin: 0 auto;
        }
    }

    .m__around{
        
        a{
            width: 206px;

            @include bp-576{
                width: auto;
            }
        }

        svg{
            width: 67px;
        }
    }
}
}

.-full .tab-content.around-the-world .cont__left img{
    width: 70%;
}


.separator{
    display: none;

    @include bp-576{
        display: block;
        border-top: 3px solid #000;
        margin: 13px 0 20px 0;
    }
}

.raya{
    display: none;
    @include bp-576{
        display: block;
        width: 90%;
        margin: 0 auto;
        background: linear-gradient(to bottom left, $clr-gray calc(50% - 3px), black , $clr-gray calc(50% + 3px) );
    }   
}

.raya__dt{
        display: block;
        width: 90%;
        height: 70px;
        margin: 0 auto;
        background: linear-gradient(to bottom left, $clr-gray calc(50% - 2px), black , $clr-gray calc(50% + 2px) );

    @include bp-576{
        display: none;
    }   
}

.home{
    // margin-top: 615px;
    margin-top: -65px;
    margin-bottom: 32vh;
    max-width: 100%;

    @include bp-1024{
        margin-top: 11vh;
    }

    @include bp-576{
        margin-top: -10px;
        margin-bottom: 50vh;
    }

    @include bp-375{
        margin-top: -19px;
    }

    

    img{
        display: block;
        border-bottom: 3px solid #000;
        width: 100%;
        
        @include bp-576{
            width: 100%;
            height: 100vh;
            display: block;
            object-fit: cover;
        }
    }
}

.about{
    @include margin-inicio;

    @include container-padding;
    @include borders;

    .-content{
        margin-bottom: 50px;
        @include bp-576{
            flex-direction: column-reverse;
            padding-bottom: 35px;
        }

        h2{
            width: 55%;
            margin: 0 auto 30px auto;

            @include bp-576{
                margin: 0 auto 15px auto;
                width: 100%;
            }
        }

        p{
            width: 60%;
            margin: 0 auto 20px auto;
        }

        @include triptico;

        .cont__right{
            // background: linear-gradient(to bottom left, $clr-gray calc(50% - 3px), black , $clr-gray calc(50% + 2px) );

            &.diagonal{
                background: linear-gradient(to bottom right, $clr-gray calc(50% - 3px), black , $clr-gray calc(50% + 2px) );
            }

            @include bp-576{
                margin: 0 auto;
                width: 100%;
                height: 75px;
                border-right: 3px solid #000;
                border-left: 3px solid #000;
                background: $clr-gray;

            }

            svg{
                @include bp-576{
                width: 21%;
                }
            }

            svg line{
                @include bp-576{
                    stroke-width: 9px;
                }
            }
        }

        .cont__left{
            @include bp-576{
                display: none;
            }

            &.diagonal{
                background: linear-gradient(to bottom left, $clr-gray calc(50% - 3px), black , $clr-gray calc(50% + 2px) );
            }

            svg{
                @include bp-576{
                width: 21%;
                }
            }

            svg line{
                @include bp-576{
                    stroke-width: 6px;
                }
            }
        }

        .raya{
            @include bp-576{
                height: 100%;
                width: 96%;
                // background: linear-gradient(to bottom left, #EDEDED calc(50% - 2px), black, #EDEDED calc(50% + 2px));
                @include diagonal;
            } 
        }
    } 

    &.bc{
        @include bp-576{
            margin-top: 0;
        }  
        
        .-content{
            @include bp-576{
                flex-direction: column;
            }   
        }

        .separator{
            display: none;
        }

        .cont__left{
            @include bp-576{
                display: block;
                width: 100%;
                // transform: rotate(90deg);
                border-left: none;
                // margin: -85px auto -105px auto;
                margin: 0 0 25px 0;
                border-bottom: 3px solid #000;
                border-right: none;
                padding: 0px 0 10px 0;

                img:first-child{
                    top: -10px;
                    border-bottom: 3px solid #000;
                    border-top: 3px solid #000;
                    padding-bottom: 20px;
                    padding-top: 20px;
                }

                img:last-child{
                    top: 10px;
                    border-bottom: 3px solid #000;
                    padding-bottom: 20px;
                }
            }

            .bc-ilus{
                display: flex;
                display:-webkit-box;
                display:-webkit-flex;
                display:-webkit-flexbox;
                flex-direction: column;
                align-items: center;

                @include bp-576{
                    display: block;
                }

                svg{
                    &:nth-child(2){
                    margin-top: -20px;
                    }
                
                    @include bp-576{
                        // background: pink;
                        // transform: rotate(90deg);
                    }
                }

                svg{
                    @include bp-576{
                        height: auto;
                        border-right: 3px solid #000;
                        border-left: 3px solid #000;
                        width: 49%;
                        padding: 12px;

                        &:first-child{
                            border-right: none;
                        }

                        line{
                            stroke-width: 5px;
                        }
                    } 
                }
            }
        }

        .cont__right{
            @include bp-576{
                display: none;
            }
        }
    }

    &.experiences{
        .separator{
            @include bp-576{
                display: block;
                margin: -8px 0 20px 0;
            }
        }
        .-content{
            top: 0px;

            @include bp-576{
                flex-direction: column-reverse;
            }

            .raya{
                @include bp-576{
                    width: 95%;
                }
            }
        }

        .cont__left{
            display: block;

            @include bp-576{
                display: none;
            }
        }

        .cont__right{
            display: block;
            // background: linear-gradient(to bottom left, #EDEDED calc(50% - 3px), black, #EDEDED calc(50% + 2px));
            @include diagonal;

            @include bp-576{
                background: transparent;
                margin-bottom: 20px;
            }
        }

        .cont__middle{
            margin: 0 auto;
        }
    }
}

.contact-experiences{
    padding: 15px 0;
    text-align: center;
    margin-top: 60px;
    border-top: 3px solid #000;
    border-bottom: none !important;

    a{
        transition: all .3s;
        &:hover{
            color: $clr-rojo-bt
        }
    }
}

.parrafo{
    border-right: 3px solid #000;
    border-left: 3px solid #000;
    margin: 40px 1%;
    background: $clr-gray;
    @include triptico;

    p{
        width: 60%;
        margin: 0 auto 20px auto;
    }

    .cont__left{
        padding: 20px 0 0 0;

        @include bp-576{
            width: 100%;
            border: none;
            margin-bottom: 20px;
        }

        img{
            @include bp-576{
                width: 45%;
                margin: 0 auto 20px auto;  
            } 
        }
    }

    .cont__right{
        // background: linear-gradient(to bottom left, $clr-gray calc(50% - 3px), black , $clr-gray calc(50% + 2px) );
        @include diagonal2;
    }

    .-content{
        @include bp-576{
            display: block;
        }
    }
}

.catalog{
    @include triptico;
    @include margin-inicio;
    @include container-padding;

    border-right: 3px solid #000;
    border-left: 3px solid #000;
    margin-bottom: 50px;

    p{
        width: 60%;
        margin: 0 auto 20px auto;
    }

    .cont__right{
        background: none;
        padding: 65px 20px;

        img{
            margin: 0 auto;
        }
    }

    &.spaces.s__detail .cont__left{
        @include bp-576{
            margin-bottom: 0 !important;
        }
    }

    .cont__left {
        background: none !important;

        &::before{
            @include bp-576{
                display: none;
            }
        }

        &::after{
            @include bp-576{
                display: none;
            }
        }

        // @include bp-576{
        //     @include diagonal2;
        // }
        img{
            @include bp-576{
                width: 40%;
            }
        }
    }
}

.spaces{
    @include triptico;
    @include margin-inicio;
    @include container-padding;
    @include borders;

    margin-bottom: 50px;

    p{
        width: 60%;
        margin: 0 auto 20px auto;
    }

    h2{
        @include bp-576{
            margin: 0 auto 15px auto;
        }
    }

   .-content{
        @include bp-576{
            display: block;   
        }
    }

    .cont__right{
        background: none;
        padding: 65px 20px;

        @include bp-576{
            display: none;
        }

        img{
            margin: 0 auto;
        }
    }

    .separator{
        @include bp-576{
            margin: 13px 0 20px 0;
        }  
    }

    .cont__left{
        // background: linear-gradient(to bottom right, $clr-gray calc(50% - 3px), black , $clr-gray calc(50% + 2px) );
        @include diagonal2;
        margin: 0 20px;

        @include bp-576{
            margin: 0 auto;
            width: 100%;
            background: none;
            padding: 0;
            height: 70px;

            // .raya{
            //     background: linear-gradient(to bottom right, $clr-gray calc(50% - 2px), black , $clr-gray calc(50% + 2px) );
            // }

            &::after{
                content: "";
                height: 472%;
                border-left: 3px solid #000;
                position: absolute;
                transform: rotate(-80deg);
                left: 50%;
                top: -187%;
            }

            &::before{
                content: "";
                height: 472%;
                border-left: 3px solid #000;
                position: absolute;
                transform: rotate(80deg);
                left: 50%;
                top: -187%;
            }

        }
        
    }    

    &.s__detail{
        padding-top: 50px;
        .cont__left{
            background: none; 

            @include bp-576{

                &::after,&::before {
                display: none;
                }

                height: auto;
                margin-bottom: 50px;
            }
            


        }
    }

    &.catalog{
        @include margin-inicio;
    }
}

@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { 
    .back {
        margin-top: -48px !important;
    }
}

.back{
    margin-top: -13px;
    z-index: 999;

    @include bp-1024{
        margin-top: 11vh;
    }

    @include bp-576{
        margin-top: -14px;
        z-index: 99;
    }

    border-bottom: 3px solid #000;
    padding: 2px 0;

    .banner {
        width: 100%;
        overflow: hidden
      }
      
      .banner .b__content {
        width: 7000px;
        display: inline-block;
        animation: marquee 15s linear infinite;
        @include bp-576{
            width: 5000px;
            animation: marquee 20s linear infinite;
        }
        

        a{
            text-transform: uppercase;
            font-size: size(38);
            margin: 0 10px;
            transition: color .3s;
            &::before{
                content: url(../img/arrow-back.svg);
                margin-right: 20px;
                @include bp-576{
                    top: 4px;
                }
            }
            @include bp-576{
                font-size: size(25);
            }

            &:hover{
                color: $clr-rojo-bt;
            }
        }
      }
      @keyframes marquee {
        0% {
          margin-left: 0;
        }
        100% {
          margin-left: calc(-5000px + 1508px);
        }
      }
      
      .banner .b__content:hover {
          -webkit-animation-play-state: paused;
          -moz-animation-play-state: paused;
          -o-animation-play-state: paused;
          animation-play-state: paused;
      }
}

.producers{
    .-content{
        .cont__right{
            background: none;

            img{
                @include bp-576{
                    width: 23%;
                }
            }
        }

        .cont__left{
            margin: 0 20px;
            // background: linear-gradient(to bottom right, $clr-gray calc(50% - 3px), black , $clr-gray calc(50% + 2px) );
            @include diagonal2
        }   
    }
}


footer{
    margin: 20px 1%;
    border-right: 3px solid #000;
    border-left: 3px solid #000;
    position: fixed;
    z-index: -99999999;
    bottom: 0;
    height: 28vh;
    width: 98%;

    @include bp-576{
        height: 45vh;
    }

    @include bp-375{
        height: 44vh;
    }
    
    .foot{    
        @include link__a;
        @include triptico;
    
        .cont__middle{
            flex-direction: row;
            justify-content: space-between;
    
            @include bp-576{
                width: 100%;
                font-size: size(12);
                overflow: auto;
                height: 32vh;
                margin-bottom: 49vh !important;

                p{
                    margin-bottom:15px;
                }
                div a{
                    margin-top:15px;
                }
                
                .columna-flex{
                    height: 25vh;
                    font-size: size(16);
                    
                    a{
                        font-size: size(15); 
                    }

                    div{
                        font-size: size(15); 
                    }
                }
            }

            @include bp-375{
                margin-bottom: 50vh !important;
            }
        }
    
        .cont__left{
            padding: 10px;
    
            @include bp-576{
                border: none;
                padding: 20px;
                width: 60%;
                margin-left: -50px;
            }
        }
    
        @include bp-576{
            display: block !important;
    
            .cont__right, .f__rrss{
                display: none;
            }
    
            .-flex{
                display: block;
            }
        }
    
        #img-load{
            img {        
                width: 70%;
                margin: 0 auto;

                @include bp-576{
                    width: 95%;
                }
            }
        }
    }
}

.w__space{
    display: block;
    height: 615px;
    background: $clr-gray;
    // height: 585px;
    
    @include bp-576{
        height: 65px !important;
    }

    // &.fixi2{
    //     height: 470px !important;
    // }
}


.-full{
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
    margin-bottom: 32vh !important;
    background-color: $clr-gray;

    @include bp-576{
        margin-bottom: 49vh !important;
    }

    @include bp-375{
        margin-bottom: 50vh !important;
    }

    &.toptop{
        top: 300px;
    }

    &.spacios{
        img{
            @include bp-576{
                height: auto;
            }
        }
    }

    img{
        // border-bottom: 3px solid #000;
        margin:0;
        width: 100%;
        width: 100%;
        display: block;
        object-fit: cover;
        border-bottom: 3px solid #000;

        &:last-child{
            border-bottom: none;
        }

        @include bp-576{
            height: 55vh;
        }
    }

    &.-doble{
        margin-bottom: 32vh !important;

        @include bp-576{
            margin-bottom: 49vh !important;
        }

        @include bp-375{
            margin-bottom: 50vh !important;
        }

        .cover{
            width: 50%;
            border-bottom: none;
            &:first-child{
                border-right: 3px solid #000;

                @include bp-576{
                    border-bottom: 3px solid #000;
                    border-right: none;
                }
            }
            @include bp-576{
                width: 100%;
                height: 50vh;
            }
        }

        .-flex{
            @include bp-576{
                display: block;
            }
        }
    }

    &.triple{
        .-flex{
            flex-wrap: wrap;
            @include bp-576{
                display: block;
            }
            .espacio{
                width: 33.3333%;
                border-right: 3px solid #000;
                &:last-child{
                    border-right: none;
                }

                @include bp-576{
                    width: 100%;
                    border-right: none;
                    border-top: 3px solid #000;

                    &:first-child{
                        border-top: none;
                    }
                }

                h2{
                    width: 77%;
                    margin: 50px auto;
                }

                img{
                    margin: 0 auto;
                    display: block;
                    padding: 20px 0;
                    justify-content: center;
                    height: 55vh;
                    width: auto;
                }

                a{
                    transition: all .2s;

                    &:hover{
                        color: $clr-rojo-bt;
                    }
                }
                

                a.plusinfo{
                    border-top: 3px solid #000;
                    display: block;
                    padding: 36px;
                    text-align: center;
                    margin-top: 40px;
                    transition: all .2s;

                    &:hover{
                        color: $clr-rojo-bt;
                    }

                    @include bp-576{
                        border-top: none;
                        margin-bottom: 20px;
                        margin-top: 0px;
                        padding: 40px 0 20px 0;

                    }
                }

                .-flex{

                    @include bp-576{
                        height: auto;
                    }
                }
            }
        }
    }

    .catalog-download{
        margin: 0 auto;
        text-align: center;
        padding: 25px 0;
        border-bottom: 3px solid #000;

        @include bp-576{
            padding: 20px 0;
        }
        a{
            transition: all .2s;
            
            &:hover{
                color: $clr-rojo-bt;
            }

            @include bp-576{
                font-size: size(30);
            }
        }
    }

    input {
    position: absolute;
    opacity: 0;
    z-index: -1;
    }

    .row {
    display: flex;
    display:-webkit-box;
    display:-webkit-flex;
    display:-webkit-flexbox;
    }
    .row .col {
    flex: 1;
    }

    /* Accordion styles */
    .tabs {
    overflow: hidden;
    }

    .tab {
    width: 100%;
    overflow: hidden;
    text-align: center;
    border-top: 3px solid #000;

        &:first-child{
            border-top: none;
        }
    }
    .tab-label {
    padding: 1em;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;

    h3{
        transition: color .3s;
        &:hover{
            color: $clr-rojo-bt;
        }
    }
    /* Icon */
    }
    .tab-label:hover {
    }

    .tab-content {
    max-height: 0;
    // padding: 0 1em;
    transition: max-height 0.35s;
    @include triptico;
    
        .-content{
            border-left: 3px solid #000;
            border-right: 3px solid #000;
            @include container-padding;

            @include bp-576{
                display: block;
            }

            .separator{
                display: none;
            }

            .cont__right{
                margin: 0 13px;
                @include diagonal
                // background: linear-gradient(to bottom left, $clr-gray calc(50% - 3px), black , $clr-gray calc(50% + 2px) )
            }

            .cont__left{
                width: 23%;

                @include bp-576{
                    width: 100%;
                    border-right: none;
                    border-left: none;
                }
            }
            
            p{
                width: 60%;
                margin: 20px auto 0 auto;
                text-align: left;

                &:last-child{
                    margin:0 auto;
                }

                @include bp-576{
                    width: 100%;
                }
            }

            img{
                // width: 60%;

                @include bp-576{
                    width: 60%;
                    padding: 0 20px;
                    height: auto;
                    margin: -40px 0;
                }
            }
        }

        .cont__middle{
            a{
                margin: 40px 0;
                display: block;
                @include link__a;
                font-size: size(55);
                line-height: size(57);

                @include bp-1440 {
                    font-size: size(34);
                    line-height: size(42); 
                }
                @include bp-768 {
                    font-size: size(30);
                    line-height: size(38); 
                }
            }
        }
        
    }
    .tab-close {
    display: flex;
    display:-webkit-box;
    display:-webkit-flex;
    display:-webkit-flexbox;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;
    }
    .tab-close:hover {
    }

    input:checked + .tab-label {
    }
    input:checked + .tab-label::after {
    transform: rotate(90deg);
    }
    input:checked ~ .tab-content {
    max-height: 100vh;
    padding: 0;

    @include bp-576{
        max-height: 150vh;
    }

    @include bp-375{
        max-height: 250vh;
    }
    }
}


.-no--foot{
    margin-bottom: 0vh !important;
}

.-has--foot{
    margin-bottom: 32vh !important;
}


.productos{
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
    padding: 40px 0 20px 0;
    text-align: left;
    background: $clr-gray;

    &.listado{
        border-top: none;
        border-bottom: none;
    }

    ul{
        border-top: 3px solid #000;
        border-bottom: 3px solid #000;
        padding: 20px 0 0 0;
        display: flex;
        display:-webkit-box;
        display:-webkit-flex;
        display:-webkit-flexbox;
        flex-wrap: wrap;

        &.productores{
            border-bottom: none;
        }
        
        li{
            border-left: 3px solid #000;
            padding: 0 30px;
            margin-bottom: 20px;

            &:last-child{
                border-right: 3px solid #000;
            }

            a{
                transition: all .3s;
                &:hover{
                    color: $clr-rojo-bt;
                }
            }
        }

        .-flex{
            flex-wrap: wrap;
        }
    }
}

.grandc{
    margin-top:30px;

    @include bp-576{
        margin-top: 13px;
    }
}

.place{
    height: 100vh;
    border-bottom: 3px solid #000;
    margin-bottom: 32vh !important;

    @include bp-576{
        display: block;
        margin-bottom: 49vh !important;
    }

    @include bp-375{
        margin-bottom: 50vh !important;
    }

    .place-1{
        width: 50%;
        border-right: 3px solid #000;
        &:last-child{
            border: none;
        }

        @include bp-576{
            width: 100%;
            border: none;
        }

        h2{
            margin: 40px auto;
        }

        h3{
            margin:0 0 15px 0;
            text-transform: uppercase;
        }
        h3,p,a{
            font-size: size(15);
            line-height: size(20);
        }

        a{
            margin-top: 15px;
            transition: color .3s;
            display: block;
            &:hover{
                color: $clr-rojo-bt;
            }
        }

        .container__bt{
            margin-left: 2%;

            @include bp-576{
                margin-left: 3%;
            }
            div{
                margin-bottom: 65px;
            }
        }
    }
}

#map {
    height: 100%;
    width: 100%;

    @include bp-576{
        height: 67.5vh;
    }
}



.pag-basica{
    @include margin-inicio;

    p, a{
        font-size: size(16);
        line-height: size(20);
    }

    h1{
        text-transform: uppercase;
        font-size: size(55);
        line-height: size(60);
    }


    h2{
        text-transform: uppercase;
        font-size: size(30);
        line-height: size(35);
        text-align: left;
    }

    h3{
        text-transform: uppercase;
        font-size: size(20);
        line-height: size(25);
        text-align: left;
        margin: 0 0 10px 0;
    }
}

.pwcmb-widget__text{
    a{
        font-size: size(20);
        line-height: size(20);
        display: inline-block;
    }
}

.error404{
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a{
        transition: color .3s;
        &:hover{
            color: $clr-rojo-bt;
        }
    }
}

















// :root
// {
// 	--nav-height: 50px;
// 	--w-height: 280px;
// 	--opacity-lightbox-background: 0.6;
// 	--opacity-loading: 0.5;
// 	--opacity-disabled: 0.3;
// 	--duration--very-fast: 75ms;
// 	--duration--fast: 150ms;
// 	--duration: 200ms;
// 	--duration--slow: 400ms;
// }
// 	@media(min-width: 1000px)
// {
// 	:root
// {
// 	--body-padding: 30px;
// 	--nav-height: 60px;
// }

// }
// 	@media(min-width: 650px)and (max-width: 999px)
// {
// 	:root
// {
// 	--body-padding: 30px;
// 	--nav-height: 60px;
// }

// }
// 	@media(max-width: 320px)
// {
// 	:root
// {
// 	--body-font-size-medium: 0.875rem
// }

// }
// 	@media(max-width: 799px)
// {
// 	:root
// {
// 	--w-height: 180px;
// 	--admin-toolbar-height: 40px
// }

// }
// 	@media(min-width: 800px)
// {
// 	:root
// {
// 	--audio-player-height: 70px
// }

// }

// 	html
// {
// 	height:100%
// }

// 	*,::after,::before
// {
// 	box-sizing:inherit
// }


// .w
// {
// 	position:fixed;
// 	display:flex;
// 	flex-flow:column wrap;
// 	justify-content:flex-end;
// 	width:calc(100% - var(--body-padding)*2);
// 	max-width:1602px;
// 	padding:1px;
// 	top:var(--body-padding);
// 	opacity:0;
// 	transition:opacity var(--duration--very-fast) ease-out;
// 	left:var(--body-padding);
// 	right:var(--body-padding);
// 	z-index:102;
// 	pointer-events:none
// }
// .w.active
// {
// 	opacity:1
// }
// .w__link
// {
// 	pointer-events:all
// }
// .w__text
// {
// 	display:none;
// 	position:absolute;
// 	top:0;
// 	object-fit:contain
// }
// .w__svg
// {
// 	display:block;
// 	width:100%;
// 	fill:none;
// 	stroke:var(--color-text);
// 	overflow:visible
// }
// .w__svg polyline
// {
// 	vector-effect:non-scaling-stroke;
// 	stroke-width:2;
// 	stroke-miterlimit:80;
// 	stroke-linejoin:miter;
// 	stroke-linecap:butt
// }
// 	@media(min-width: 650px)and (max-width: 999px)
// {
// .w__svg polyline
// {
// 	stroke-width:1.5
// }

// }
// 	@media(max-width: 649px)
// {
// .w__svg polyline
// {
// 	stroke-width:1
// }

// }
// 	@media(min-width: 800px)
// {
// .w
// {
// 	left:auto;
// 	right:auto
// }

// }





























