#cookie-manager{
    p{
        font-size: size(20);
        line-height: size(24);
        width: 80%;
        font-weight: normal;

        a{
            text-decoration: underline;
        }
    }
}

.pwcmb-widget__actions{
    font-size: size(20);
    line-height: size(24);
    font-weight: normal;
    cursor: pointer;

    .js-pwcmb-allow-cookies{
        margin-right: 15px;
        text-decoration: underline;
        // background: rgba(0,0,0,0.2);
        // padding: 10px 15px;
        // border-radius: 2px;
    }
}

.pwcmb-widget__close::before{
    top: -7px;
}