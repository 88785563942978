.-table {
    width: 100%;
    display: table;
    border: 1px solid $clr-gray-light;
}
.-table__cell {
    display: table-cell;
    padding: size(5) size(10);
    vertical-align: middle;
    border-bottom: 1px solid $clr-gray-light;
    font-weight: $font-light;
}
.-table__row {
    display: table-row;
    &.-table__row--head {
        .-table__cell {
            padding: size(10);
            text-transform: uppercase;
            font-weight: $font-medium;
            @include bg-color($clr-gray-light, 1);
        }
    }
    &:last-child {
        .-table__cell {
            border: 0;
        }
    }
}
