#modal-container {
  position:fixed;
  display:table;
  height:100%;
  width:100%;
  top:0;
  left:0;
  transform:scale(0);
  z-index:1;

  &.two {
    transform:scale(1);
    z-index: 99;
    .modal-background {
      background: rgba(217, 217, 217, 0.5) !important;
      backdrop-filter: blur(15px);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      .modal {
        opacity:0;
        animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    + .content {
      animation: scaleBack .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    &.out {
      animation: quickScaleDown 0s .5s linear forwards;
      .modal-background {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        .modal {
           animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }
      + .content {
        animation: scaleForward .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
  }


  .modal-background {
    display:table-cell;
    background:rgba(255,255,255,.5);
    text-align:center;
    vertical-align:middle;
    
    .close{
      width: 50px;
      height: 50px;
      cursor: pointer;
      position: absolute;
      bottom: 28px;
      left: 18%;

      &.tienda{
        right: 0 !important;
        left: auto;
        bottom: 0;
      } 
      

      &::after{
        content: " ";
        display: block;
        width: 30px;
        height: 2px;
        background-color: #000000;
        transform: rotate(45deg);
        position: absolute;
        top: 25px;
        left: 10px;
        transition: transform 300ms ease-in-out;
      } 
      
      &::before{
        content: " ";
        display: block;
        width: 30px;
        height: 2px;
        background-color: #000000;
        transform: rotate(-45deg);
        position: absolute;
        transition: transform 300ms ease-in-out;
        left: 10px;
        top: 25px;
        }
      }  
    }

    .modal {
      padding:50px;
      display:inline-block;
      border-radius:3px;
      font-weight:300;
      position:relative;

      p {
        font-size:size(50);
        line-height:size(55);
        font-family: $font-primary-book;

        @include bp-1440{
          font-size:size(30);
          line-height:size(35);
        }
      }
      .modal-svg {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        border-radius:3px;
        rect {
          stroke: #fff;
          stroke-width: 2px;
          stroke-dasharray: 778;
          stroke-dashoffset: 778;
        }
      }
    }
  }


.content {
  min-height:100%;
  height:100%;
  background:white;
  position:relative;
  z-index:0;
  h1 {
    padding:75px 0 30px 0;
    text-align:center;
    font-size:30px;
    line-height:30px;
  }
  .buttons {
    max-width:800px;
    margin:0 auto;
    padding:0;
    text-align:center;
    .button {
      display:inline-block;
      text-align:center;
      padding:10px 15px;
      margin:10px;
      font-size:18px;
      background-color:#efefef;
      border-radius:3px;
      box-shadow:0 1px 2px rgba(0,0,0,.3);
      cursor:pointer;
      &:hover {
        color:white;
        background:#009bd5;
      }
    }
  } 
}

@keyframes fadeIn {
  0% {
    background:rgba(0,0,0,.0);
  }
  100% {
    background:rgba(0,0,0,.7);
  }
}

@keyframes fadeOut {
  0% {
    background:rgba(0,0,0,.7);
  }
  100% {
    background:rgba(0,0,0,.0);
  }
}

@keyframes scaleUp {
  0% {
    transform:scale(.8) translateY(1000px);
    opacity:0;
  }
  100% {
    transform:scale(1) translateY(0px);
    opacity:1;
  }
}

@keyframes scaleDown {
  0% {
    transform:scale(1) translateY(0px);
    opacity:1;
  }
  100% {
    transform:scale(.8) translateY(1000px);
    opacity:0;
  }
}

@keyframes scaleBack {
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(.85);
  }
}

@keyframes scaleForward {
  0% {
    transform:scale(.85);
  }
  100% {
    transform:scale(1);
  }
}

@keyframes quickScaleDown {
  0% {
    transform:scale(1);
  }
  99.9% {
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
}











