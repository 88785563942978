$screen-375: 375px;
$screen-576: 576px;
$screen-768: 768px;
$screen-820: 820px;
$screen-1024: 1023px;
$screen-1280: 1280px;
$screen-1366: 1366px;
$screen-1440: 1440px;
$screen-1600: 1600px;
$screen-1680: 1680px;
$screen-1920: 1920px;

@mixin safari{
    @media screen and (-webkit-min-device-pixel-ratio:0) { 
        @content; 
    }
}

@mixin bp-375 {
    @media ( max-width: #{ $screen-375 } ) {
        @content; 
    }
}

@mixin bp-576 {
    @media ( max-width: #{ $screen-576 } ) {
        @content; 
    }
}

@mixin bp-768 {
    @media ( max-width: #{ $screen-768 } ) {
        @content; 
    }
}

@mixin bp-820 {
    @media ( max-width: #{ $screen-820 } ) {
        @content; 
    }
}

@mixin bp-1024 {
    @media ( max-width: #{ $screen-1024 } ) {
        @content; 
    }
}

@mixin bp-1280 {
    @media ( max-width: #{ $screen-1280 } ) {
        @content; 
    }
}

@mixin bp-1366 {
    @media ( max-width: #{ $screen-1366 } ) {
        @content; 
    }
}

@mixin bp-1440 {
    @media ( max-width: #{ $screen-1440 } ) {
        @content; 
    }
}

@mixin bp-1600 {
    @media ( max-width: #{ $screen-1600 } ) {
        @content; 
    }
}

@mixin bp-1680 {
    @media ( max-width: #{ $screen-1680 } ) {
        @content; 
    }
}

@mixin bp-1920 {
    @media ( max-width: #{ $screen-1920 } ) {
        @content; 
    }
}

@mixin bp-1600 {
    @media ( max-width: #{ $screen-1600 } ) {
        @content; 
    }
}

@mixin bp( $screen ) { 
    @media ( max-width: $screen+'px' ) { 
        @content; 
    }
}