// Positioning
@mixin position($position, $args) {
    @each $o in top right bottom left {
        $i: index($args, $o);
        @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
            #{$o}: nth($args, $i + 1);
        }
    }
    position: $position;
}
@mixin absolute($args: '') {
    @include position(absolute, $args);
}

@mixin fixed($args: '') {
    @include position(fixed, $args);
}

@mixin relative($args: '') {
    @include position(relative, $args);
}
// Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity = $opacity-ie); //IE8
}

// Gradients
@mixin gradient($direction, $list) { 
    background: -webkit-linear-gradient($direction, $list);
    background: -moz-linear-gradient($direction, $list);
    background: -o-linear-gradient($direction, $list);
    background: linear-gradient($direction, $list);  
}

// Background cover
@mixin bgcover(){
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
// Color
@mixin bg-color($color, $opacity) {
    background-color: rgba($color, $opacity);
}
@mixin color($color, $opacity) {
    color: rgba($color, $opacity);
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin line-height($size) {
  line-height: $size;
  line-height: calculateRem($size);
}

@mixin lazy {
    opacity: 0;
    transition: opacity 0.7s ease-in;  
}

@mixin centrarvert{
    display: flex;   
    align-items: center;}

@mixin h2pos{
    @include font-size(50);
    @include font-line-height(55);
    font-weight: $font-semibold;
    text-transform: uppercase;
    @include bp-1280{
        @include font-size(40);
        @include font-line-height(45);
    }
}



// Convert to rem
// The default font size for html is 16px
$base-size: 16;
//Function to convert px value into rem
//You can replace rem with any other value you want (rem is most preferred value for web)
@function size($target, $context: $base-size) {
    @return ($target / $context) * 1rem;
}


//CUSTOM MIXINS
@mixin margin-inicio{
    // // margin-top: 585px;
    // &.dist-top{
    //     margin-top: 60px;
    // }

    // &.menu__on{
    //     margin-top: 50px;
    // }

    // @include bp-1024{
    //     margin-top: 11vh;
    // }

    // @include bp-576{
    //     margin-top: 9vh;
    // }
}

@mixin diagonal{
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><line x1="0" y1="0" x2="100%" y2="100%" stroke="black" stroke-width="3"/></svg>');
}


@mixin diagonal2{
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><line x1="100%" y1="0" x2="0" y2="100%" stroke="black" stroke-width="3"/></svg>');
}




@mixin triptico{
    .cont__right, .cont__left{
        width: 20%;
    }

    .cont__middle{
        width: 60%;
        padding: 0 20px;
        font-size: size(15);
        line-height: size(18);
        border-right: 3px solid #000;
        border-left: 3px solid #000;

        flex-direction: column;
        justify-content: center;
        display: flex;

        p, a{
            font-size: size(15); 
            line-height: size(20);

            @include bp-576{
                width: 98%;
            }
        }

        p{
            &:last-child{
                margin-bottom:0;
            }
        }

        @include bp-576{
            width: 100%;
            border-right: none;
            border-left: none;
        }
    }

    .cont__left{
        padding: 65px 20px;

        img{
            margin: 0 auto;
            display: block;

            @include bp-576{
                padding: 0 35px;
            }
        }

        @include bp-576{
            border-right: 3px solid #000;
            border-left: 3px solid #000;
        }
    }

    .cont__right{
        margin: 0 20px;
        // background: linear-gradient(to bottom right, $clr-gray calc(50% - 3px), black , $clr-gray calc(50% + 2px) );
        @include diagonal;

        @include bp-576{
            // border-right: 3px solid #000;
            // border-left: 3px solid #000;
        }
    }
}

@mixin link__a{
    a{
        &:hover{
            color: $clr-rojo-bt;
        }
    }
}

@mixin container-padding{
    margin-left: 1%;
    margin-right: 1%;
}

@mixin borders{
    border-right: 3px solid #000;
    border-left: 3px solid #000;

    @include bp-576{
        border-right: none;
        border-left: none;
        padding-left: 2%;
        padding-right: 2%;
    }
}

