// Default colors
$clr-white: rgb(255, 255, 255);
$clr-black: rgb(0, 0, 0);
$clr-gray-light: rgb(244, 244, 244);
$clr-gray-medium: #8B8B8B;
$clr-gray-dark: #464749;
$clr-txt: #000;
$clr-error: rgb(215, 40, 40);
$clr-hover: #ff7276; 
$clr-gray: #EDEDED;
$clr-rojo-bt: #F93C15;

// App colors
$clr-primary: #000;
$clr-secondary: rgb(92, 121, 139);

// Background colors
.-bg--white { 
    @include bg-color($clr-white, 1);
    @include color($clr-txt, 1); 
}
.-bg--black { 
    @include bg-color($clr-black, 1);
    @include color($clr-white, 1);  
}
.-bg--gray-light { 
    @include bg-color($clr-gray-light, 1);
    @include color($clr-txt, 1); 
}
.-bg--gray-medium { 
    @include bg-color($clr-gray-medium, 1);
    @include color($clr-txt, 1); 
}
.-bg--gray-dark { 
    @include bg-color($clr-gray-dark, 1);
    @include color($clr-white, 1); 
}
.-bg--primary {
    @include bg-color($clr-primary, 1);
    @include color($clr-white, 1);
} 
.-bg--secondary {
    @include bg-color($clr-secondary, 1);
    @include color($clr-white, 1);
} 
