.-slider--default {
    width: 100%;
    height: 100%;
    .-slider__wrap {
        height: 100%;
    }
    .-slide {
        width: 100%;
        height: 100%;
        min-height: 40vh;
        .-pic {
            height: 100%;
        }
    }
    .-slider__controls {
        @include absolute(bottom size(50) right size(50));
        svg {
            @include opacity(1);
            cursor: pointer;
            transform: translateX(0);
            transition: all .1s linear;
            &.-slide__prev {
                &:hover {
                    transform: translateX(size(-5));
                }
            }
            &.-slide__next {
                &:hover {
                    transform: translateX(size(5));
                }
            }
            &:hover {
                @include opacity(.7);
            }
            &.is--hide {
                display: none;
            }
        }
    }
}