// Font weights
$font-thin: 100;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

/*
    OPTION A: Google fonts
    OPTION B: Custom fonts
*/

/*
    OPTION A: Google fonts
*/

// Maven Pro: 
// @import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600&display=swap');
/*
    OPTION B: Custom fonts
*/

@font-face {
    font-family: 'Ulrich';
    src: url('../fonts/Ulrich-Regular.ttf') format('truetype');
    src: url('../fonts/Ulrich-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ulrich-Regular.eot'),
        url('../fonts/Ulrich-Regular.woff') format('woff'),
        url('../fonts/Ulrich-Regular.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

$font-primary: 'Ulrich', sans-serif;
$font-primary-book: 'Ulrich', sans-serif;
$font-mono: 'Ulrich', sans-serif;
$font-mono2: 'Ulrich', sans-serif;

