/*
    FONT VARIABLES
*/

@import 'fonts-import.scss';


// Default
$font-primary: $font-primary, sans-serif;
$font-weight: $font-regular;
$font-italic: 'italic';

/*
    MIXINS
*/
/*
    MIXIN: Font color
    ARGUMENTS:
        -   $color[ rgb ]
        -   $opacity [ float ]
            Defines a of rgba.
*/
@mixin font-color($color, $opacity) {
    color: rgba($color, $opacity);
}
/*
    MIXIN: Font size
    ARGUMENTS:
        -   $size[ int ]
*/
@mixin font-size($size) {
    font-size: size($size);
}
/*
    MIXIN: Font line height
    ARGUMENTS:
        -   $size[ int ]
*/
@mixin font-line-height($size) {
    line-height: size($size);
}

// HTML (In case of WHO iframe: app-obr)
html {
    font-family: $font-primary;
    font-weight: $font-regular;
    @include color($clr-txt, 1);
}
// Headings
h1,
.-h1 { 
    font-family: $font-primary;
    font-weight: $font-medium;
    color: #000000;

    font-size: size(65);
    line-height: size(68);
    margin: 60px 0 10px 0;

    @include bp-1440{
        font-size: size(55);
        line-height: size(57);
    }

    @include bp-1024{
        margin: 40px 0 10px 0;
    }

    @include bp-576{
        font-size: size(35);
        line-height: size(38);
        margin: 25px 0 10px 0;
    }
}

h2,
.-h2 { 
    margin-top: 0; 
    margin-bottom: size(30);
    font-family: $font-primary;
    font-weight: $font-regular;
    @include font-size(55); 
    @include font-line-height(57);
    text-transform: uppercase;
    text-align: center;
    &:last-child { margin-bottom: 0; }
    @include bp-1440 {
        @include font-size(34);
        @include font-line-height(42); 
    }
    @include bp-768 {
        @include font-size(30);
        @include font-line-height(38); 
    }
}
h3,
.-h3 { 
    margin-top: 0; 
    margin-bottom: size(30);
    font-family: $font-primary;
    font-weight: normal;
    @include font-size(55); 
    @include font-line-height(46);
    &:last-child { margin-bottom: 0; }
    @include bp-1440 {
        @include font-size(34);
        @include font-line-height(42); 
    }
    @include bp-768 {
        @include font-size(30);
        @include font-line-height(38); 
    }  
}
h4,
.-h4 { 
    margin-top: 0; 
    margin-bottom: size(10);
    font-family: $font-primary;
    font-weight: $font-regular;
    @include font-size(25); 
    @include font-line-height(23);
    &:last-child { margin-bottom: 0; }
    @include bp-768 {
        @include font-size(21);
        @include font-line-height(21); 
    }
    
}
h5,
.-h5 {
    margin-top: 0; 
    margin-bottom: size(15);
    font-family: $font-primary;
    font-weight: $font-bold;
    @include font-size(18); 
    @include font-line-height(23); 
    &:last-child { margin-bottom: 0; }
}
h6,
.-h6 { 
    margin-top: 0; 
    margin-bottom: size(20); 
    font-family: $font-primary;
    font-weight: $font-weight;
    @include font-size(19); 
    @include font-line-height(19); 
    &:last-child { margin-bottom: 0; }
}
p { 
    margin-top: 0; 
    margin-bottom: size(20);
    font-family: $font-primary-book;
    font-weight: $font-weight;
    @include font-size(30); 
    @include font-line-height(39);

    @include bp-1440 {
        @include font-size(22);
        @include font-line-height(29); 
    }
    
    @include bp-768 {
        @include font-size(16);
        @include font-line-height(22); 
    }
    strong {
        font-weight: $font-semibold;
    }
    &:last-of-type { margin-bottom: 0; } 
    &.-small {
        @include font-size(16); 
        @include font-line-height(24);
    }
    a {
        @include color($clr-txt, 1);
    }
}
a {
    @include color( $clr-txt, 1);
    text-decoration: none;
    font-family: $font-primary;
    
    @include font-size(55); 
    @include font-line-height(57);
    @include bp-1440 {
        @include font-size(34);
        @include font-line-height(42); 
    }
    @include bp-768 {
        @include font-size(30);
        @include font-line-height(38); 
    }
    
}
strong {
    font-weight: $font-bold;
}
