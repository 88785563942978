
.slide-idiomas{
    .dropdown-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .dropdown {
        background: transparent;
        padding: 10px;
        border-radius: 3px;
        width: 15%;
        display: flex;
        justify-content: space-around;
        font-size: 1.1rem;
        cursor: pointer;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
        border: 1px solid #FFF;
        color: #FFF;
        // margin-top:40vh;
        @include bp-1280{
            width: 23%;
        }
    }
    
    .fa-angle-down {
        position: relative;
        top: 0;
        font-size: 1.3rem;
        transition: transform 0.3s ease;
    }
    
    .rotate-dropdown-arrow {
        transform: rotate(-180deg);
    }
    
    .dropdown-menu {
        display: none;
        flex-direction: column;
        border-radius: 4px;
        margin-top: 8px;
        width: 15%;
        padding: 10px;
        box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
        background: #fafafa;
        transform-origin: top left;
        z-index: 999999;
        @include bp-1280{
            width: 23%;
            padding: 0;
        }
    }
    
    .dropdown-menu span {
        padding: 10px;
        flex-grow: 1;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease;
    }
    
    .dropdown-menu span:last-child {
        border: none;
    }
    
    .dropdown-menu span:hover {
        background: #eee;
    }
    
    #openDropdown:checked + .dropdown-menu {
        display: flex;
        animation: openDropDown 0.4s ease;
    }
    
    @keyframes openDropDown {
        from { transform: rotateX(50deg); }
        to { transform: rotateX(0deg); }
    }
}