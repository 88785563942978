// Margin: custom
@for $value from 1 through 2000 {

    .-mr--#{$value} {
        margin-right: size($value);
    }
    .-mb--#{$value} {
        margin-bottom: size($value);
    }
    .-ml--#{$value} {
        margin-left: size($value);
    }
    .-mv--#{$value} {
        margin-top: size($value);
        margin-bottom: size($value);
    }
    .-mh--#{$value} {
        margin-right: size($value);
        margin-left: size($value);
    }
}

// Margin: auto
.-mh--auto {
    margin-right: auto;
    margin-left: auto;
}

// Margin: remove
.-mv--0 {
    margin-top: 0;
    margin-bottom: 0;
}
.-mh--0 {
    margin-right: 0;
    margin-left: 0;
}
.-m--0 {
    margin: 0;
}

@mixin margin-top {
    margin-top: 140px;

    @include bp-576 {
        margin-top: 30px;
    }
}
