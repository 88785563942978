.-ssmm {
    display: flex;
    align-items: center;
    li {
        margin-right: size(15);
        a {
            display: flex;
            align-items: center;
            > * {
                &:first-child {
                    margin-right: size(5);
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
}