.-checkbox {
    display: flex;
    align-items: center;
    label {
        cursor: pointer;
    }
    .-checkbox__wrap {
        width: 18px;
        height: 18px;
        margin-right: size(10);
        input {
            width: 18px;
            height: 18px;
            @include opacity(0);
            cursor: pointer;
        }
        .-checkbox__outline {
            width: 100%;
            height: 100%;
            @include absolute(top 0 left 0);
            border: 1px solid $clr-gray-medium;
        }
        .-checkbox__fill {
            width: calc( 100% - 7px );
            height: calc( 100% - 7px );
            @include absolute( top 50% left 50% );
            transform: translate( -50%, -50% );
            @include bg-color( $clr-primary, 1 );
            @include opacity(0);
        }
    }
    &.-checkbox--disabled {
        label {
            cursor: not-allowed;
        }
        .-checkbox__wrap {
            @include opacity(.2);
            input {
                cursor: not-allowed;
            }
        }
    }
}