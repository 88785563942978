$btn-font-size: 18;
$btn-font-weight: 500;
$btn-padding-top: 20;
$btn-padding-right: 40;
$btn-padding-bottom: 20;
$btn-padding-left: 40;
$btn-transition-time: .2s;
$btn-transition-ease: linear;
$btn-radius: 0;
.-btn {
    display: inline-flex;
    overflow: hidden;
    border-radius: size($btn-radius);
    cursor: pointer;
    span {
        padding-top: size($btn-padding-top);
        padding-right: size($btn-padding-right);
        padding-bottom: size($btn-padding-bottom);
        padding-left: size($btn-padding-left);
        @include font-size($btn-font-size);
        font-weight: $btn-font-weight;
        transition: all $btn-transition-time $btn-transition-ease;
        &.-btn__hide {
            width: 100%;
            height: 100%;
            @include absolute();
        }
        @include bp-1440 {
            padding-top: size(15);
            padding-right: size(30);
            padding-bottom: size(15);
            padding-left: size(30);
            @include font-size(16);
        }
    }
}
.-btn--full {
    display: flex;    
    span {
        width: 100%;
        text-align: center;
    }
}
.-btn--opacity {
    span {
        @include opacity(1);
    }
    &:hover {
        span {
            @include opacity(.5); 
        }
    }
}
.-btn--ft {
    span {
        transform: translateY(0);
        &.-btn__hide {
            top: -100%;
            left: 0;
        }
    }
    &:hover {
        span {
            transform: translateY(100%);
        }
    }
}
.-btn--fr {
    span {
        transform: translateX(0);
        &.-btn__hide {
            top: 0;
            left: 100%;
        }
    }
    &:hover {
        span {
            transform: translateX(-100%);
        }
    }
}
.-btn--fb {
    span {
        transform: translateY(0);
        &.-btn__hide {
            top: 100%;
            left: 0;
        }
    }
    &:hover {
        span {
            transform: translateY(-100%);
        }
    }
}
.-btn--fl {
    span {
        transform: translateX(0);
        &.-btn__hide {
            top: 0;
            left: -100%;
        }
    }
    &:hover {
        span {
            transform: translateX(100%);
        }
    }
}
.-btn--inline {
    border-radius: 0;
    span {
        padding: 0;
    }
    &.-btn--small {
        span {
            padding: 0;
        }
    }
}
.-btn--state {
    span {
        &.-btn__status {
            width: 100%;
            height: 100%;
            @include absolute();
            display: flex;
            align-items: center;
            justify-content: center;
            @include opacity(0);
            z-index: 1;
            transform: none!important;
            svg {
                max-height: 20px;
            }
            .spinner {
                width: 70px;
                text-align: center;
                > div {
                    width: 10px;
                    height: 10px;
                    @include bg-color($clr-white, 1);
                    border-radius: 100%;
                    display: inline-block;
                    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
                    &.bounce1 { animation-delay: -0.32s; }
                    &.bounce2 { animation-delay: -0.16s;}
                }
            }
        }
    }
}

.-btn--small {
    span {
        padding: size(10) size(35);
        @include font-size(14);
    }
}

.-btn--black {
    span {
        @include bg-color($clr-black, 1);
        @include color($clr-white, 1);
    }
}

.-btn--secondary {
    span {
        @include bg-color($clr-secondary, 1);
    }
}

.-btn--primary {
    span {
        @include bg-color($clr-primary, 1);
        @include color($clr-black, 1);
    }
    &.-btn--inv {
        border: 1px solid $clr-primary;
        span {
            @include bg-color($clr-black, 1);
            @include color($clr-primary, 1);
        }
    }
    &:disabled {
        span {
            cursor: not-allowed;
            @include bg-color($clr-gray-medium, 1);
        }
    }
}

.-btn--white {
    span {
        @include bg-color($clr-white, 1);
        @include color($clr-primary, 1);
    }
}


@keyframes sk-bouncedelay { 
    0%, 80%, 100% { 
        transform: scale(0);
    } 40% { 
        transform: scale(1.0);
    }
}