/*
    GRID DEFAULTS
*/
$grid-layout: 12;
$grid-default-gutter: 50;
$grid-default-breakpoint: true;
$grid-default-breakpoint-width: 768;
$grid-default-brakpoint-gutter: 10;


/*
    GRID MIXINS
*/

/*
    MIXIN: Grid breakpoint
    ARGUMENTS:
    -   $breakpoint[ px ]
        Width of grid breakpoint
    -   $columns[ int ]
        Columns of the new grid
    -   $gutter[ int - default: $grid-default-gutter ]
        Gutter of the new grid
*/


/*
    GRID ELEMENT
*/

/*  Grid    */
.-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
}

.-margin__0auto{
    margin: 0 auto;
}


/*  
    MODIFIER: No wrap the grid (Fluid space) 
*/
.-grid--nowrap {
    flex-wrap: nowrap;
    > .-box {
        flex: unset;
    }
}


/*  
    MODIFIER: Grid gutter
    ARGUMENTS:
    -   $value[ int ]
        Defines custom gutter in px(to rem).
*/

.imagen{
    display: block;
    object-fit: cover;
}


/*  
    MODIFIER: Grid justify & alignments
*/

.-grid--justify-start { justify-content: flex-start; }
.-grid--justify-end { justify-content: flex-end; }
.-grid--justify-around { justify-content: space-around; }
.-grid--justify-between { justify-content: space-between; }
.-grid--justify-center { justify-content: center; }
.-grid--align-baseline { align-items: flex-baseline; }
.-grid--align-start { align-items: flex-start; }
.-grid--align-end { align-items: flex-end; }
.-grid--align-around { align-items: space-around; }
.-grid--align-between { align-items: space-between; }
.-grid--align-center { align-items: center; }

/*
    BOX ELEMENT
*/

/*  Box */
.-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: size($grid-default-gutter);
    > * {
        &:last-child {
            margin-bottom: 0;
        }
    } 
}

/*  
    MODIFIER: Box fraction
    ARGUMENTS:
    - $value [int - Min: 1]
    - $grid-layout [ int - default: 12 ]
    Defines specific box width in a fraction of twelve.
    Example: Box width 50% of row -> $value = 6.
*/


.centrar-vertical{
    @include centrarvert;
    height: 88vh;
}

.columna-flex{
    display: flex;
    flex-direction: column;
}

.row-flex{
    display: flex;
    flex-direction: row;
}

.-flex{
    display: flex;
}

.dropdown {
    float: left;
    z-index: 999999;
  }
  
  .dropdown .dropbtn {
    color: #000;
    margin: 0;
    text-align: center;
  }
  
  .navbar a:hover, .dropdown:hover .dropbtn {
    z-index: 99999;
  }
  
  .dropdown-content {
    display: none;
    position: fixed;
    z-index: 1;
    background-color:#EBEBEB;
    width: 100vh;
    left: 50%;
    transform: translateX(-50%);

    @include bp-1440{
        margin-top: -6px;
    }

    @include bp-1366{
        margin-top: -2px;
    }

    @include bp-1280{
        margin-top: 8px;
    }

    &.idiomas{
   
        flex-direction: column;
        left: 10px;
        width: 120%;
        position: relative;
        background-color: #FFF;

        a{
            text-align: center; 
            padding: 2vh 0 !important;           
        }

    }

  }

 






