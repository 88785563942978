// Padding: custom
@for $value from 1 through 2000 {
    
    .-pr--#{$value} {
        padding-right: size($value)!important;
    }
    .-pb--#{$value} {
        padding-bottom: size($value)!important;
    }
    .-pl--#{$value} {
        padding-left: size($value)!important;
    }
    .-pv--#{$value} {
        padding-top: size($value)!important;
        padding-bottom: size($value)!important;
    }
    .-ph--#{$value} {
        padding-right: size($value)!important;
        padding-left: size($value)!important;
    }
}

// Padding: remove
.-pv--0 {
    padding-top: 0!important;
    padding-bottom: 0!important;
}
.-ph--0 {
    padding-right: 0!important;
    padding-left: 0!important;
}
.-p--0 {
    padding: 0!important;
}
